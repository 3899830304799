<template>
  <div class="youjiaopage_wrap">
    <nav2 />
    <BreadCrumb />
    <!--  -->
    <div class="center land-box NewsDetails">
      <div class="NewsDetails_cont">
        <div class="NewsDetails_cont_title">
          <h1>{{ newsDetail.title }}</h1>
          <div class="NewsDetails_cont_title_span">
            <img src="../assets/img/newsDetail/矩形.png" alt="" />
            <span style="margin-left: 4px">发布时间：{{ newsDetail.releaseTime }}</span>
          </div>
        </div>
        <div class="NewsDetails_conttext">
          <p v-html="newsDetail.content"></p>
        </div>
      </div>
    </div>

    <footer2></footer2>
  </div>
</template>

<script>
import footer2 from "../components/footer.vue";
import nav2 from "../components/nav.vue";
import CommonService from "@/service/CommonService";

export default {
  name: "keji",
  components: {
    footer2,
    nav2,
  },
  created() {
    this.loadNewsDetail();
  },
  data() {
    return {
      newsDetail: {},
      h1: "企业新闻",
      Img1: require("../assets/img/keji/矿山banner1.png"),
      // 这是我在父的组件里 定义的变量 表示当前是数组里第1个页面
      artcleID: 0,

      p1: "更多最新精彩资讯，感受企业魅力",
    };
  },

  methods: {
    loadNewsDetail() {
      CommonService.getNewsDetail(this.$route.query.id).then((res) => {
        this.newsDetail = res.data;
      });
    },
  },
};
</script>
<style scoped lang="scss">
/* 新闻详情 */
.NewsDetails_cont {
  width: 1200px;
  margin: 0 auto;
}
.NewsDetails_cont_title > h1 {
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #2b2b2b;
  line-height: 33px;
  margin-top: 78px;
  text-align: center;
}
.NewsDetails_cont_title img {
  vertical-align: middle;
}
.NewsDetails_cont_title_span {
  text-align: center;

  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #a3a3a3;
  line-height: 30px;
  margin-top: 8px;
}
.NewsDetails_conttext {
  margin-top: 40px;
  border-top: 1px solid #eaeaea;
}
.NewsDetails_conttext p {
  line-height: 30px;
  width: 1000px;
  margin: 40px 100px;
}
.NewsDetails_conttext div {
  margin-left: 90px;
  font-size: 24px;
  font-weight: bold;
}

.NewsDetails_cont_btm {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}
.NewsDetails_cont_btm div {
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  line-height: 33px;
}
.NewsDetails_cont_btm p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.NewsDetails_cont_btm2 {
  display: flex;
  justify-content: space-between;
}
.NewsDetails_cont_btm2_1 {
  margin: 32px 0;
  padding: 31px 61px 31px 20px;
  width: 507px;
  height: 123px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.11);
}
.NewsDetails_cont_btm2_1 div {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 25px;
}
.NewsDetails_cont_btm2_1 p {
  margin-top: 10px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 28px;
}

/*** */
::v-deep .NewsDetails_conttext {
  color: #333;
  font-size: 14px;

  strong {
    font-weight: bold !important;
  }

  > p {
    margin: 0 auto !important;
    p {
      margin: 15px 0;
    }
  }
}

/* table 样式 */
::v-deep .NewsDetails_conttext table {
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
}
::v-deep .NewsDetails_conttext table td,
::v-deep .NewsDetails_conttext table th {
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding: 3px 5px;
}
::v-deep .NewsDetails_conttext table th {
  border-bottom: 2px solid #ccc;
  text-align: center;
}

/* blockquote 样式 */
::v-deep .NewsDetails_conttext blockquote {
  display: block;
  border-left: 8px solid #d0e5f2;
  padding: 5px 10px;
  margin: 10px 0;
  line-height: 1.4;
  font-size: 100%;
  background-color: #f1f1f1;
}

/* code 样式 */
::v-deep .NewsDetails_conttext code {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  background-color: #f1f1f1;
  border-radius: 3px;
  padding: 3px 5px;
  margin: 0 3px;
}
::v-deep .NewsDetails_conttext pre code {
  display: block;
}

/* ul ol 样式 */
::v-deep .NewsDetails_conttext ul,
ol {
  margin: 10px 0 10px 20px;
}
</style>
